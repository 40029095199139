import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCard,{attrs:{"width":"30%","min-width":"300px"}},[_c(VToolbar,{staticClass:"primary"},[_c(VToolbarTitle,{staticClass:"card-title"},[_vm._v(" LeasingLama ")])],1),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VCardText,[_c(VTextField,{attrs:{"dark-gray--text":"","outlined":"","label":"Benutzername","prepend-inner-icon":"mdi-account"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"outlined":"","label":"Passwort","prepend-inner-icon":"mdi-form-textbox-password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{attrs:{"color":"success","block":"","type":"submit","disabled":!(_vm.username && _vm.password)}},[(!_vm.isLoggingIn)?[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-login ")]),_vm._v(" Anmelden ")]:_vm._e(),(_vm.isLoggingIn)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"white"}}):_vm._e()],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }