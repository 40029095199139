import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"secondary",attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{attrs:{"block":""},on:{"click":_vm.logout}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-logout")]),_vm._v(" Abmelden ")],1)],1)]},proxy:true}]),model:{value:(_vm.isNavigationVisible),callback:function ($$v) {_vm.isNavigationVisible=$$v},expression:"isNavigationVisible"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h7 dark-gray--text"},[_vm._v(" Lama-Board ")]),_c(VListItemSubtitle,{staticClass:"dark-gray--text"},[_vm._v(" Fahrzeugverwaltung ")])],1)],1),_c(VDivider),_c(VList,{attrs:{"nav":""}},_vm._l((_vm.navigationItemsForRole),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.navigate(item.to)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"dark-gray--text"},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c(VListItem,[_c(VSwitch,{attrs:{"hint":"","inset":"","label":"Dark mode","persistent-hint":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }