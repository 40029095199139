<template>
  <div>
    <app-bar title="Inserate" />
    <VehicleList />
  </div>
</template>

<script>
import VehicleList from "../components/VehicleList.vue";
import AppBar from "../components/views/AppBar.vue";

export default {
  name: "Home",
  components: {
    VehicleList,
    AppBar,
  },
};
</script>
