import store from '../store/store';

const onSuccess = (response) => {
    return response;
};

const onError = (error) => {

    return Promise.reject(error);
};

const beforeRequestSuccess = (config) => {
    config.headers.Authorization = `Bearer ${store.getters['auth/accessToken']}`;
    return config;
};

const beforeRequestError = (error) => {
    return Promise.reject(error);
}

export { onSuccess, onError, beforeRequestSuccess, beforeRequestError };