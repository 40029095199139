<template>
  <v-app>    
      <notifications position="top center"/>
    <router-view name="navigation" />
    <v-main>
      
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "../node_modules/typeface-roboto/index.css";


@font-face {
  font-family: "Greed-Bold";
  src: local("Greed-Bold"),
   url(./assets/Greed-Bold.ttf) format("truetype");
}

@font-face {

  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
  url(./assets/Inter-Regular.ttf) format("truetype"); 
}

#app {
  font-family: Inter-Regular;
}


.card-title,
.v-toolbar__title,
.v-tabs-slider-wrapper,
.v-tab {
  font-family: Greed-Bold;
}

.v-tabs-bar__content {
  color: orange!important;
}

.required label:after {
    color: #e32;
    content: ' *';
    display:inline;
}

.v-input,
.v-input textarea {
  font-weight: bold;

}

</style>
