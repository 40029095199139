import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URI;

export default {
    getAll() {
        return axios.get(apiUrl+'/User').then(res => res.data).catch(err => Promise.reject(err));
    },
    getUser(userId) {
        return axios.get(apiUrl+'/User/'+userId).then(res => res.data).catch(err => Promise.reject(err));
    },
    createUser(user) {
        return axios.put(apiUrl+'/User/', user).then(res => res.data).catch(err => Promise.reject(err));
    },
    updateUser(user) {
        return axios.post(apiUrl+'/User', user).then(res => res.data).catch(err => Promise.reject(err));
    },
    validateCustomerNumber(customerNumber) {
        return axios.post(apiUrl+"/User/ValidateCustomerNumber/"+customerNumber).then(res => res.data).catch(err => Promise.reject(err));
    },
    generateApiKey(userId) {
        return axios.post(apiUrl+'/User/RegenerateApiKey/'+userId).then(res => res.data).catch(err => Promise.reject(err));
    },
    me() {
        return axios.get(apiUrl+"/User/Me").then(res => res.data).catch(err => Promise.reject(err));
    }
}