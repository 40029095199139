<template>
  <v-navigation-drawer class="secondary" app v-model="isNavigationVisible">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h7 dark-gray--text">
          Lama-Board
        </v-list-item-title>
        <v-list-item-subtitle class="dark-gray--text">
          Fahrzeugverwaltung
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav>
      <v-list-item
        v-for="item in navigationItemsForRole"
        :key="item.title"
        link
        @click="navigate(item.to)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="dark-gray--text">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
      <v-list-item>
        <v-switch
        v-model="$vuetify.theme.dark"
        hint=""
        inset
        label="Dark mode"
        persistent-hint
      ></v-switch>
</v-list-item>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logout">
          <v-icon left>mdi-logout</v-icon>
          Abmelden
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  data() {
    return {
      items: [
        { title: "Inserate", icon: "mdi-car-side", to: "Home" },
        {
          title: "Benutzerverwaltung",
          icon: "mdi-account-group",
          requiredRole: 0,
          to: "users",
        },
        {
          title: "Administration",
          icon: "mdi-shield-crown",
          requiredRole: 0,
          to: "administration"
        },
        {
          title: "Datenimport",
          icon: "mdi-database-import",
          to: "import",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "auth/userInfo",
    }),
    isNavigationVisible: {
      get() {
        return this.$store.state.navigationBar.isVisible;
      },
      set(value) {
        this.$store.dispatch("hideNavigation", value);
      },
    },
    navigationItemsForRole() {
      let items = [];

      for (let i = 0; i < this.items.length; i++) {
        if (Object.prototype.hasOwnProperty.call(this.items[i], "requiredRole")) {
          if (this.items[i].requiredRole == this.userInfo.role) {
            items.push(this.items[i]);
          }
        } else {
          items.push(this.items[i]);
        }
      }

      return items;
    },
  },
  methods: {
    navigate(to) {
      if (this.$route.name !== to) {
        this.$router.push({ name: to });
      }
    },
    logout() {
      this.$store.dispatch("setLoggedIn", false);
      this.$router.push({ name: "Login" });
      localStorage.removeItem("isLoggedIn");
    },
  },
};
</script>

<style>
  .v-application .primary--text {
  color: #f6f6f6 !important;
}
</style>
