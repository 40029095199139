<template>
  <v-container fill-height>
  
    <v-row justify="center" align="center">
      <v-card width="30%" min-width="300px">
        <v-toolbar class="primary">
          <v-toolbar-title class="card-title"> LeasingLama </v-toolbar-title>
        </v-toolbar>
        <v-form @submit.prevent="login">
          <v-card-text>
            <v-text-field dark-gray--text
              outlined
              label="Benutzername"
              prepend-inner-icon="mdi-account"
              v-model="username"
            ></v-text-field>

            <v-text-field
              outlined
              label="Passwort"
              prepend-inner-icon="mdi-form-textbox-password"
              type="password"
              v-model="password"
            ></v-text-field>
            <v-btn
              color="success"
              block
              type="submit"
              :disabled="!(username && password)"
            >
              <template v-if="!isLoggingIn">
                <v-icon left> mdi-login </v-icon>
                Anmelden
              </template>
              <v-progress-circular
                indeterminate
                color="white"
                v-if="isLoggingIn"
              ></v-progress-circular>
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  props: ["targetPath"],
  data() {
    return {
      username: "",
      password: "",
      isLoggingIn: false,
    };
  },
  methods: {
    ...mapActions({
      loginUser: "auth/authenticate",
    }),
    login() {
      let loginModel = {
        username: this.username,
        password: this.password,
      };

      this.loginUser(loginModel)
        .then(() => {
          this.$store.dispatch("setLoggedIn", true);
          this.$router.push(this.targetPath || "/", () => {});
        })
        .catch(() => {
          this.$notify({
            title: 'Login fehlgeschlagen',
            text: 'Bitte prüfen Sie Ihre Eingaben.',
            type: 'error'
          })
          this.$store.dispatch("setLoggedIn", false);
        });
    },
  },
};
</script>
